<template>
  <div>
    <van-nav-bar
      title="巡检任务"
      left-arrow
      :border="false"
      fixed
      @click-left="$router.go(-1)"
    >
      <template #title>
        <div class="risk-title-box">
          <span
            v-for="item in tabs"
            :key="item.value"
            :class="{ active: curTab === item.value }"
            class="risk-title-item"
            @click.prevent="tabChange(item)"
            >{{ item.text }}</span
          >
        </div>
      </template>
    </van-nav-bar>
    <div class="header">
      <div class="filter-box-wrapper">
        <van-search
          v-model="query.name"
          placeholder="请输入计划名字和关键字"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div class="alarm-search">
          <div class="date-time-box">
            <date-time
              v-model="dateTime"
              :time-close="true"
              type="datetime"
              format="{y}-{m}-{d} {h}:{i}"
            />
          </div>
        </div>
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.isActive || item.text }]"
            @click="selectCon(item.popupKey)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="alarm-total">共{{ total }}个结果</div>
        </div>
      </div>
    </div>
    <div class="content">
      <van-pull-refresh
        ref="vanList"
        v-model="refreshing"
        class="alarm-list"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="loadError"
          error-text="加载失败，请稍后再试！"
          @load="onLoad"
        >
          <div
            v-for="item in taskList"
            :key="item.id"
            class="alarm-item"
            @click="gotoDetail(item.id)"
          >
            <div class="alarm-header">
              <div class="alarm-title">
                <div class="alarm-name-box">
                  <div class="name-text-box">
                    <span class="name">{{ item.name }}</span>
                  </div>
                  <span v-if="item.supplementFlag === 1" class="make-up-tag"
                    >补录</span
                  >
                  <span
                    v-if="+item.passStatus === 2"
                    class="make-up-tag make-approve-tag"
                    >补录审批中</span
                  >
                </div>
                <div class="status-box">
                  <van-icon
                    name="xunjian"
                    class-prefix="iconfont"
                    class="iconfont-xunjian"
                    size="16"
                  />
                  <span class="equipment-name">{{
                    dealType(item.taskTypeName)
                  }}</span>
                  <van-icon
                    name="riqi"
                    class-prefix="iconfont"
                    class="iconfont-riqi"
                    size="15"
                  />
                  <span class="equipment-name">{{
                    `${item.startTime ||
                      item.validStartTime ||
                      "--:--:--"} - ${item.endTime ||
                      item.validEndTime ||
                      "--:--:--"}`
                  }}</span>
                </div>
              </div>
              <div class="alarm-status">
                <!-- <img :src="disposalStatusImgSrcSwitch(item.checkStatus)" /> -->
                <van-icon
                  :name="statusIconSwitch(item.status)"
                  class-prefix="iconfont"
                  :class="`iconfont-${statusIconSwitch(item.status)}`"
                  size="44"
                />
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <SelectPopupGrade
      v-model="popupVisibleObj[1].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[1].title"
      :list.sync="popupVisibleObj[1].list"
      :visible="popupVisibleObj[1].visiable"
      @change="v => popupGradeChange(v, 0, 1)"
      @close="popupVisibleObj[1].visiable = false"
    />
    <SelectPopupGrade
      v-model="popupVisibleObj[2].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[2].title"
      :list.sync="popupVisibleObj[2].list"
      :visible="popupVisibleObj[2].visiable"
      @change="v => popupGradeChange(v, 1, 2)"
      @close="popupVisibleObj[2].visiable = false"
    />
    <SelectPopupGrade
      v-model="popupVisibleObj[3].value"
      :immediately="false"
      list-key="value"
      :multi="true"
      :title="popupVisibleObj[3].title"
      :list.sync="popupVisibleObj[3].list"
      :visible="popupVisibleObj[3].visiable"
      @change="v => popupGradeChange(v, 2, 3)"
      @close="popupVisibleObj[3].visiable = false"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getTaskTypeSltData,
  getTaskStatusSltData,
  getInspectQulityList,
  getInspectionPatrolList
} from "@/api/psm/inspectionNew";
// NOTE: filterList和popupVisibleObj参考alarm内使用

export default {
  name: "InspectionNew",
  components: {},
  data() {
    return {
      dateTime: {
        start: "",
        end: ""
      },
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      query: {
        name: "",
        startTime: "",
        endTime: "",
        taskType: "",
        statusList: "0,1",
        checkStatus: "",
        typeFlag: 1,
        sort: "validStartTime_asc",
        page: 1,
        size: 10
      },
      filterList: [
        {
          name: "任务类型",
          type: "select",
          text: "",
          popupKey: 1,
          isActive: false
        },
        {
          name: "状态",
          type: "select",
          popupKey: 2,
          text: "",
          isActive: false
        },
        {
          name: "巡检质量",
          type: "select",
          popupKey: 3,
          text: "",
          isActive: false
        }
      ],
      popupVisibleObj: {
        1: {
          visiable: false,
          title: "任务类型",
          value: "",
          list: []
        },
        2: {
          visiable: false,
          title: "状态",
          value: "",
          list: []
        },
        3: {
          visiable: false,
          title: "巡检质量",
          value: "",
          list: []
        }
      },
      total: 0,
      taskList: [],
      curTab: 0,
      tabs: [
        {
          text: "待执行",
          value: 0
        },
        {
          text: "已结束",
          value: 1
        }
      ]
    };
  },
  computed: {
    statusIconSwitch() {
      return status => {
        const obj = {
          0: "weikaishi",
          1: "jinhangzhong",
          2: "yiwancheng",
          3: "yiguoqi"
        };
        return obj[status];
      };
    }
    // disposalStatusImgSrcSwitch() {
    //   return checkStatus => {
    //     const obj = {
    //       0: yihulue,
    //       1: daiqueren,
    //       2: daichuzhi,
    //       3: daiheyan,
    //       4: yiwancheng
    //     };
    //     return obj[checkStatus];
    //   };
    // }
  },
  watch: {
    dateTime: {
      handler(val) {
        console.log("val", val);
        if (val.start && val.end) {
          this.query.startTime = `${val.start}:00`;
          this.query.endTime = `${val.end}:00`;
          this.onSearch();
        }
        if (!val.start && !val.end) {
          this.query.startTime = "";
          this.query.endTime = "";
          this.onSearch();
        }
      },
      deep: true
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.onLoad();
    // window.sessionStorage.removeItem("redirectURL");
    // 设置初始时间，为当天开始时间和第二天开始时间
    // this.dateTime.start = dayjs()
    //   .startOf("day")
    //   .format("YYYY-MM-DD HH:mm");
    // this.dateTime.end = dayjs()
    //   .add(1, "day")
    //   .startOf("day")
    //   .format("YYYY-MM-DD HH:mm");
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    dealType(type) {
      let temp = "";
      if (!type) {
        temp = "-";
      } else if (type === "点巡检") {
        temp = "设备巡检";
      } else {
        temp = type;
      }
      return temp;
    },
    tabChange(tab) {
      this.curTab = tab.value;
      this.filterList[1].text = "";
      this.query.statusList = tab.value === 0 ? "0,1" : "2,3,4,5,6";
      this.query.sort =
        tab.value === 0 ? "validStartTime_asc" : "validStartTime_desc";
      this.onSearch();
    },
    onSearch(val) {
      this.query.page = 1;
      this.taskList = [];
      this.total = 0;
      this.getList();
    },
    onCancel() {
      this.onSearch();
    },
    async getList() {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      try {
        const { list, total } = await getInspectionPatrolList(this.query);
        this.total = total;
        this.loadError = false;
        this.loading = false;
        if (list && list.length) {
          this.taskList = this.taskList.concat(list);
          this.query.page++;
        } else {
          this.finished = true;
        }
      } catch (err) {
        this.loading = false;
        this.finished = true;
        this.loadError = true;
      }
    },
    onLoad() {
      if (this.refreshing) {
        this.taskList = [];
        this.query.page = 1;
        this.total = 0;
        this.refreshing = false;
      }
      this.getList();
    },
    onRefresh() {
      this.query.page = 1;
      this.taskList = [];
      this.total = 0;
      this.getList();
    },
    async selectCon(popupKey) {
      let data = [];
      if (popupKey === 1) {
        data = await getTaskTypeSltData();
      } else if (popupKey === 2) {
        const res = await getTaskStatusSltData();
        const s = this.curTab === 0 ? this.curTab : this.curTab + 1;
        const e = this.curTab === 0 ? 2 : res.length;
        data = res.slice(s, e);
      } else if (popupKey === 3) {
        data = await getInspectQulityList();
      }
      console.log(data);
      this.popupVisibleObj[popupKey].list = data.map(v => {
        if (v.label === "点巡检") {
          v.label = "设备巡检";
        }
        return v;
      });
      this.popupVisibleObj[popupKey].visiable = true;
    },
    popupGradeChange(v, filterListIdx, popupKey) {
      const vObj = v.length > 0 ? v[0] : {};
      this.popupVisibleObj[popupKey].value = vObj.value || "";
      this.filterList[filterListIdx].text = vObj.label || "";
      if (popupKey === 1) {
        this.query.taskType = vObj.value || "";
      } else if (popupKey === 2) {
        this.query.statusList =
          vObj.value || (this.curTab === 0 ? "0,1" : "2,3");
      } else if (popupKey === 3) {
        //巡查质量
        if (v.length > 0) {
          this.query.checkStatus = v.map(s => s.value).join(",");
          this.filterList[2].text = v.map(a => a.label).join(",");
          this.popupVisibleObj[3].value = v.map(a => a.value);
        }

        if (v.length === 0) {
          this.popupVisibleObj[3].value = [];
          this.filterList[2].text = "";
          this.query.checkStatus = "";
        }
      }
      this.onSearch();
    },
    gotoDetail(id) {
      this.$router.push({ name: "inspectionDetailNew", params: { id } });
    }
  }
};
</script>

<style lang="scss" scoped>
.van-nav-bar {
  ::v-deep.van-nav-bar__title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .risk-title-box {
    position: relative;
    display: inline-block;
    width: 160px;
    font-size: 0;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    overflow: hidden;
    .risk-title-item {
      display: inline-block;
      width: 50%;
      height: 100%;
      font-size: 14px;
      color: #fff;
      background-color: #1676ff;
      border: 1px solid #fff;
      box-sizing: border-box;
      &.active {
        color: #1676ff;
        background-color: #fff;
        box-sizing: border-box;
      }
      &:nth-child(1) {
        border-radius: 4px 0 0 4px;
        border-right: none;
      }
      &:nth-child(2) {
        border-radius: 0 4px 4px 0;
        border-left: none;
      }
    }
  }
}
.header {
  padding: 58px 0 0;
  .search-bar {
    padding-bottom: 0;
  }
  .alarm-search {
    margin: 0;
    .date-time-box {
      padding: 12px 16px 0 16px;
      background-color: #fff;
      .datetime {
        position: relative;
        margin: 0;
      }
    }
  }
  .filter-box {
    padding: 10px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid #c7c9d0;
    &::-webkit-scrollbar {
      display: none;
    }
    .filter-item {
      display: inline-block;
      border-radius: 10px;
      background-color: #eaeff5;
      padding: 4px 6px;
      box-sizing: border-box;
      margin: 0 4px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3b4664;
      &.select {
        position: relative;
        padding-right: 18px;
        &::after {
          position: absolute;
          content: "";
          right: 6px;
          top: 8px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }
        &.active {
          &::after {
            border-top-color: $--color-primary;
          }
        }
      }
      &.active {
        background-color: #dcecff;
        color: $--color-primary;
      }
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
    }
  }
  .search-condition {
    display: flex;
    justify-content: center;
    .loading {
      display: inline-block;
    }
    .van-loading__spinner {
      width: 4vw;
      height: 4vw;
    }
    .alarm-total {
      color: #8c8f97;
      text-align: center;
      font-size: 12px;
      padding: 12px 0 0;
    }
    // .condition-switch {
    //   color: $--color-icon;
    //   i {
    //     font-size: 5vw;
    //   }
    // }
  }
}
.content {
  .van-list {
    height: calc(100vh - 229px);
  }
  .alarm-list {
    flex: 1;
    overflow-y: auto;
    z-index: 9;
    .alarm-item {
      padding: 12px 17px 13px 16px;
      border-bottom: 8px solid #eceef2;
      font-size: 4vw;
      .alarm-header {
        display: flex;
        .alarm-title {
          flex: 3;
          max-width: 70vw;
          // div {
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          //   white-space: nowrap;
          //   line-height: 1.1;
          // }
          color: #3b4664;
          .alarm-name-box {
            display: flex;
            align-items: center;
            margin: 0 0 10px;
            .name-text-box {
              // flex: 1;
              max-width: 83%;
              display: inline-block;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .alarm-name {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #3b4664;
                line-height: 20px;
              }
            }
            .make-up-tag {
              display: inline-block;
              height: 16px;
              line-height: 16px;
              font-size: 11px;
              padding: 0 6px;
              color: #fff;
              background-color: #3683f4;
              text-align: center;
              border-radius: 3px;
              margin: 0 0 0 8px;
              vertical-align: middle;
            }
            .make-approve-tag {
              color: #f53f3f;
              background-color: rgba(245, 63, 63, 0.1);
            }
          }
          .status-box {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .equipment-name {
              font-size: 12px;
              font-weight: 400;
              color: #3b4664;
              line-height: 17px;
              margin: 0 20px 0 4px;
            }
            i {
              color: #aeb3c0;
            }
          }
        }
        .alarm-status {
          flex: 1;
          text-align: right;
          .iconfont-weikaishi {
            color: #dea148;
          }
          .iconfont-jinhangzhong {
            color: #3683f4;
          }
          .iconfont-yiwancheng {
            color: #8eb433;
          }
          .iconfont-yiguoqi {
            color: #5c7ba2;
          }
        }
      }
    }
  }
}
</style>
